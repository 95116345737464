import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store'

import { APP_TITLE } from '@/config'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "dummy" */ '@/pages/dummy.page.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Startseite',
        icon: 'mdi-home'
      }
    }, {
      path: '/schedule',
      name: 'schedule',
      component: () => import(/* webpackChunkName: "schedule" */ '@/pages/schedule.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Stundenplan',
        icon: 'mdi-table-large'
      }
    }, {
      path: '/lessons-overview',
      name: 'lessons-overview',
      component: () => import(/* webpackChunkName: "lessons-overview" */ '@/pages/lessons-overview.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Themenübersicht',
        icon: 'mdi-clipboard-text'
      }
    }, {
      path: '/week-reports',
      name: 'week-reports',
      component: () => import(/* webpackChunkName: "week-reports" */ '@/pages/week-reports.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Wochenberichte',
        icon: 'mdi-calendar-text'
      }
    }, {
      path: '/absences',
      name: 'absences',
      component: () => import(/* webpackChunkName: "absences" */ '@/pages/absences.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Anwesenheiten',
        icon: 'mdi-account-check'
      }
    }, {
      path: '/book',
      name: 'book',
      component: () => import(/* webpackChunkName: "book" */ '@/pages/book.page.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Druck',
        icon: 'mdi-printer'
      }
    }, {
      path: '/info',
      name: 'info',
      component: () => import(/* webpackChunkName: "info" */ '@/pages/info.page.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Information',
        icon: 'mdi-information-outline'
      }
    }, {
      path: '/welcome',
      name: 'welcome',
      component: () => import(/* webpackChunkName: "info" */ '@/pages/info.page.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Wilkommen'
      }
    }, {
      path: '/user-profile',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "user-profile" */ '@/pages/user-profile.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Konto verwalten',
        icon: 'mdi-cog-outline'
      }
    }, {
      path: '/user-profile/password',
      name: 'user-profile-password',
      component: () => import(/* webpackChunkName: "reset-password" */ '@/pages/reset-password.page/index.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        title: 'Passwort ändern',
      }
    }, {
      path: '/manage',
      name: 'manage',
      component: () => import(/* webpackChunkName: "manage" */ '@/pages/manage/index.vue'),
      beforeEnter: isAdmin,
      children: [
        {
          path: 'users',
          name: 'manage-users',
          component: () => import(/* webpackChunkName: "manage-users" */ '@/pages/manage/users.page/index.vue'),
          meta: {
            title: 'Benutzerverwaltung',
            icon: 'mdi-account-group-outline',
          },
        },
      ],
    }, {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/pages/login.page.vue'),
      beforeEnter: isGuest,
      meta: {
        title: 'Anmelden'
      }
    }, {
      path: '/password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ '@/pages/reset-password.page/index.vue'),
      beforeEnter: isGuest,
      meta: {
        title: 'Neues Passwort'
      }
    }, {
      path: '/registration',
      name: 'registration',
      component: () => import(/* webpackChunkName: "registration" */ '@/pages/registration.page.vue'),
      beforeEnter: isGuest,
      meta: {
        title: 'Registrierung'
      }
    }, {
      path: '*',
      redirect: '/schedule'
    }
  ]
})


router.beforeEach((to, from, next) => {
  store.dispatch('navigationStarted')
  next()
})


router.afterEach(to => {
  store.dispatch('navigationDone')
  const { title } = to.matched[0].meta
  Vue.nextTick(() => {
    document.title = title ? `${title} - ${APP_TITLE}` : APP_TITLE
  })
})


router.onError(error => {
  store.dispatch('navigationFailed')
  console.error(error)
})


function isAuthenticated(to, from, next) {
  if (store.getters['isAuth']) {
    next()
  } else {
    next({name: 'login', query: {to: to.fullPath}})
  }
}


function isAdmin(to, from, next) {
  if (store.getters['isAdmin']) {
    next()
  } else {
    next({name: 'home'})
  }
}


function isGuest(to, from, next) {
  if (!store.getters['isAuth']) {
    next()
  } else {
    next({name: 'home'})
  }
}
